// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation-block {
    & {
        @include layer("navigation");
    }

    &.-fullbleed {
        background: $background;
    }

    &.-flyout {
        background: $background;
        border-right: remify(1) solid darken($background_alt, 10);
        bottom: 999em;
        display: block;
        left: -999em;
        overflow: auto;
        padding: 0;
        position: absolute;
        right: 999em;
        top: -999em;;
        transform: translateX(-100%);
        transition: bottom 0s 0.15s, left 0s 0.15s, right 0s 0.15s, top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        z-index: 999;
    }

    &.-flyout.-right {
        border-left: remify(1) solid darken($background_alt, 10);
        border-right: 0;
        left: 999em;
        right: -999em;
        transform: translateX(100%);
    }

    &.-flyout.is-active {
        bottom: 0;
        left: 0;
        right: remify(80);
        transition: transform 0.15s;
        transform: translateX(0);
        top: 0;
        visibility: visible;
    }

    &.-flyout.-right.is-active {
        left: remify(80);
        right: 0;
    }

    &.-pulldown {
        background: $background;
        border-right: remify(1) solid darken($background_alt, 10);
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: -999em;
        transform: translateY(-100%);
        transition: top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: 100%;
        z-index: 999;
    }

    &.-pulldown.is-active {
        top: 0;
        transform: translateY(0);
        transition: transform 0.15s;
        visibility: visible;
    }
}

.navigation_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(0 10);
    }

    .navigation-block.-flyout > &,
    .navigation-block.-pulldown > & {
        background: none;
        max-width: none;
        padding: remify(10 0);
    }

    .navigation-block.-fullbleed > & {
        background: none;
    }
}
