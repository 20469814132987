// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background: $primary;
        border: 0;
        clear: both;
        height: remify(1);
        margin: remify(0 0 40, 16);
        opacity: 0.5;
        width: 100%;
    }
}
