// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Quick Quote
\* ------------------------------------------------------------------------ */

.quick-quote {
    & {
        background: $primary_alt;
        background: linear-gradient(195deg, $primary 0%, $primary 70%, lighten(desaturate(adjust-hue($primary, -2.8882), 26.4663), 22.9412) 70%, lighten(desaturate(adjust-hue($primary, -2.8882), 26.4663), 22.9412) calc(70% + #{remify(1)}), $primary_alt calc(70% + #{remify(1)}), $primary_alt 100%);
        box-shadow: remify(0 0 7 1, 16) transparentize($dark, 0.75);
        margin: remify(0 0 50, 16);
        padding: remify(25 30, 16);
    }
}

.quick-quote_title {
    & {
        color: $light;
        font-size: remify(22.53, 16);
    }
}

.quick-quote_content .nf-form-cont {
    .nf-form-fields-required {
        left: -999em;
        position: absolute;
        top: -999em;
    }

    .button.-submit {
        background: $background;
        border: remify(1) solid lighten(desaturate(adjust-hue($primary, -2.8882), 26.4663), 22.9412);
        color: $primary;
        font-size: remify(15.48992226972406, 16);
        margin-bottom: 0;
        padding: remify(4.378157792460164 13.13447337738049, 15.48992226972406);
    }
}
