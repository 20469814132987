// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Fluidbox https://github.com/terrymun/Fluidbox
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_fluidbox";

// Custom

.fluidbox__overlay {
    & {
        background-color: transparentize($dark, 0.25);
    }
}
