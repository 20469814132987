// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

.menu-list_container {
    & {
        @extend %clearfix;
    }
}

.menu-list {
    & {
        @extend %clearfix;

        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 auto;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        margin: 0;
    }

    > .fa {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .fa {
        transform: rotate(-180deg);
    }
}

// center variant

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

// flex variant

.menu-list.-flex {
    & {
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 auto;
        width: 0;
    }
}

// vertical variant

.menu-list.-vertical {
    & {
        text-align: left;
    }

    &.-right {
        text-align: right;
    }

    > .menu-list_item {
        float: none;
    }
}

// tier variant

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }

    // accordion variant

    &.-accordion {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > &.-accordion {
        max-height: remify(500, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }

    // overlay variant

    &.-overlay {
        left: -999em;
        opacity: 0;
        position: absolute;
        transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        top: -999em;
        visibility: hidden;
    }

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

// tier1 variant

.menu-list.-tier1 {
    // overlay variant

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        left: 0;
        top: 100%;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 0;
    }
}

// tier2 variant

.menu-list.-tier2 {
    // overlay variant

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        left: 100%;
        top: 0;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 100%;
    }
}

// navigation variant

.menu-list.-navigation {
    & {
        font-family: $body-font;
        font-size: remify(17, 16);
        font-weight: 600;
    }

    .menu-list_link {
        color: $foreground;
        padding: remify(10 20, 17);
        transition: background-color 0.15s, color 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover,
    > .menu-list_item.-parent:hover > .menu-list_link,
    > .menu-list_item.-parent.is-active > .menu-list_link {
        color: $accent;
    }

    .menu-list_toggle {
        color: $foreground;
        font-size: 1em;
        padding: remify(8 12, 17);
        transition: background-color 0.15s, color 0.15s;
    }

    .menu-list_toggle:focus,
    .menu-list_toggle:hover,
    .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list_item.-parent.is-active >.menu-list_toggle {
        color: $accent;
    }

    .menu-list.-tier1.-accordion,
    .menu-list.-tier1.-overlay {
        background-color: $accent;
    }

    .menu-list.-tier1 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover,
    .menu-list.-tier1.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier1.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: lighten($accent, 5);
    }

    .menu-list.-tier1 .menu-list_toggle {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_toggle:focus,
    .menu-list.-tier1 .menu-list_toggle:hover,
    .menu-list.-tier1 .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list.-tier1 .menu-list_item.-parent.is-active >.menu-list_toggle {
        background-color: lighten($accent, 5);
    }

    .menu-list.-tier2.-accordion,
    .menu-list.-tier2.-overlay {
        background-color: lighten($accent, 10);
    }

    .menu-list.-tier2 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier2.-accordion .menu-list_link:focus,
    .menu-list.-tier2.-accordion .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_link:focus,
    .menu-list.-tier2.-overlay .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier2.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: lighten(lighten($accent, 10), 5);
    }
}

// sitemap variant

.menu-list.-sitemap {
    & {
        color: lighten(desaturate(adjust-hue($primary_alt, -0.1483), 29.4691), 9.7608);
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        margin: remify(0 0 19.6, 14);
    }

    .menu-list_item:not(:last-child):after {
        content: "|";
        padding: remify(0 4 0 3, 14);
    }

    .menu-list_link {
        color: $light;
        display: inline-block;
        transition: opacity 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        opacity: 0.9;
    }
}

// sitemap small variant

.menu-list.-sitemap.-small {
    & {
        font-size: remify(10, 16);
    }
}

// sitemap center variant

.menu-list.-sitemap.-center {
    & {
        float: none;
        right: auto;
        text-align: center;
    }

    .menu-list_item {
        float: none;
        display: inline-block;
        left: auto;
    }
}

// social variant

.menu-list.-social {
    & {
        font-size: remify(14, 16);
    }

    .menu-list_item {
        margin-bottom: remify(28, 14);
    }

    .menu-list_link {
        color: $page_background;
        min-height: remify(40, 14);
        padding: remify(4 0 4 50, 14);
        position: relative;
        transition: opacity 0.15s;
        text-align: left;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        opacity: 0.9;
    }

    .menu-list_icon {
        background: $light;
        border-radius: 50%;
        font-size: remify(40, 14);
        height: remify(40, 40);
        left: 0;
        padding: remify(8, 40);
        position: absolute;
        top: 0;
        width: remify(40, 40);
    }

    .menu-list_icon .icon {
        transform: translateY(#{remify(-12, 40)});
    }

    .menu-list_label {
        color: $light;
        // left: -999em;
        // position: absolute;
        // top: -999em;
    }
}

// meta variant

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

// pagination variant

.menu-list.-pagination {
    & {
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}
