// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }

    &.-fullbleed {
        background: $background;
        padding: remify(20);
    }
}

.header_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(20);
    }

    .header-block.-fullbleed > & {
        background: none;
        padding: 0;
    }
}

.header_logo {
    & {
        margin: 0 auto;
        max-width: remify(260, 16);
    }
}

.header_title {
    & {
        font-size: remify(33, 16);
    }
}

.header_text {
    & {
        font-size: remify(16, 16);
    }

    .icon {
        font-size: remify(25, 16);
        transform: translateY(#{remify(8, 25)});
    }
}
