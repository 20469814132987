// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Toggle
\* ------------------------------------------------------------------------ */

.menu-toggle {
    & {
        background: none;
        border: 0;
        color: $light;
        font-family: $heading-font;
        font-size: remify(18, 16);
        font-weight: 900;
        margin: 0;
        padding: remify(16 16 16 45, 18);
        position: relative;
        text-transform: uppercase;
    }

    &:before,
    &:after {
        background: currentColor;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(2, 18);
        left: remify(12, 18);
        margin: auto;
        position: absolute;
        top: 0;
        transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
        width: remify(24, 18);
    }

    &.is-active:before,
    &.is-active:after {
        transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
    }

    &:before {
        box-shadow: remify(0 8 0 0, 16) currentColor;
        top: remify(-16, 16);
    }

    &.is-active:before {
        box-shadow: 0 0 0 0 transparent;
        transform: rotate(45deg);
        top: 0;
    }

    &:after {
        top: remify(16, 16);
    }

    &.is-active:after {
        transform: rotate(-45deg);
        top: 0;
    }
}
