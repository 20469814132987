// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    &.-fullbleed {
        background: $page_background;
        padding: remify(40 25);
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(40 25);
    }

    .footer-block.-fullbleed > & {
        background: none;
        padding: 0;
    }
}

.footer_link {
    & {
        color: $light;
        transition: opacity 0.15s;
    }

    &:focus,
    &:hover {
        color: $light;
        opacity: 0.9;
    }
}

.footer_title {
    & {
        color: $light;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 700;
        margin-bottom: remify(4, 14);
        text-align: center;
    }

    &.-alt {
        font-family: $heading-font;
        font-size: remify(24, 16);
        margin-bottom: remify(24, 24);
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(11, 16);
        text-align: center;
    }
}

.footer_divider {
    & {
        margin-bottom: remify(24, 16);
    }
}

.footer_logo {
    & {
        margin: remify(0 auto 35, 16);
        max-width: remify(240, 16);
        opacity: 0.35;
        transition: opacity 0.15s;
    }

    &:focus,
    &:hover {
        opacity: 0.45;
    }
}

//Style BBB logo in footer
#bbb-footer {
  width: 75%;
}

@media screen and (max-width: $s-break) {
  #bbb-footer {
    width: 25%;
  }
}
@media screen and (max-width: $xs-break) {
  #bbb-footer {
    width: 50%;
  }
}
