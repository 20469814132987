// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }

    .is-fluidboxactive & {
        position: static;
    }

    &.-fullbleed {
        background: $background;
        padding: remify(15);
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(15);
    }

    .content-block.-fullbleed > & {
        background: none;
        padding: 0;
    }
}

.content_row {
    &.-padded {
        width: calc(100% + #{remify(50, 16)});
    }

    &.-padded > .col {
        padding-right: remify(50, 16);
    }
}
