// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar-block {
    & {
        @include layer("toolbar");
    }

    &.-fullbleed {
        background: $page_background;
    }
}

.toolbar_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .toolbar-block.-fullbleed > & {
        background: none;
    }
}

.toolbar_button {
    & {
        border: 0;
        color: $light;
        font-size: remify(30, 16);
        line-height: normal;
        padding: remify(10 15 4, 30);
    }
}
