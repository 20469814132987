// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }

    &.-fullbleed {
        background: $background;
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .hero-block.-fullbleed & {
        background: none;
        max-width: 100%;
    }
}
