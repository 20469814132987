// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        border: remify(1) solid $primary_alt;
        margin: remify(0 0 30, 16);
        padding: remify(4, 16);
        width: 100%;
    }

    &[href] {
        display: block;
        text-decoration: none;
    }
}

.widget_figure {
    & {
        margin: 0;
    }
}

.widget_image {
    & {
        display: block;
        width: 100%;
    }
}

.widget_title {
    & {
        color: $primary_alt;
        font-size: remify(21, 16);
        text-align: center;
        padding: remify(25 30, 21);
    }
}

.widget_content {
    & {
    }
}

// dark variant

.widget.-dark {
    & {
        background: lighten($dark, 20);
        border: remify(1) solid transparentize($light, 0.35);
        box-shadow: remify(0 0 0 3, 16) lighten($dark, 20);
        margin: remify(6 6 26, 16);
        padding: remify(20, 16);
    }

    .widget_button {
        background: lighten($dark, 38.4314);
        border: 0;
        color: $light;
        font-size: remify(14.15, 16);
    }

    .widget_title {
        color: $light;
        font-size: remify(46.4, 16);
        padding: 0;
    }

    .widget_text {
        color: $light;
        font-size: remify(17.6, 16);
    }
}
