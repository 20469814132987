// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Walkway
\* ------------------------------------------------------------------------ */

@font-face {
    font-family: "Walkway";
    src: url("../media/walkway/900/webfont.eot");
    src: url("../media/walkway/900/webfont.eot?#iefix") format("embedded-opentype"), url("../media/walkway/900/webfont.woff") format("woff"), url("../media/walkway/900/webfont.ttf") format("truetype"), url("../media/walkway/900/webfont.svg#Walkway") format("svg");
    font-style: normal;
    font-weight: 900;
}
