// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title-block {
    & {
        @include layer("title");
    }

    &.-fullbleed {
        background: lighten($dark, 20);
        padding: remify(25 20);
    }
}

.title_inner {
    & {
        background: lighten($dark, 20);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(25 20);
    }

    .title-block.-fullbleed > & {
        background: none;
        padding: 0;
    }
}

.title_title {
    & {
        color: $light;
    }
}
